import { tag_category_type } from '@acme/db';

// Ensure these values exist in tag_category_type
export const OPTIONAL_WILDCARDS = [
  tag_category_type.hat,
  tag_category_type.emotion,
] as const;

export type OptionalWildcard = typeof OPTIONAL_WILDCARDS[number];

export const isOptionalWildcard = (wildcard: string): wildcard is OptionalWildcard => {
  console.log('Checking wildcard:', wildcard, OPTIONAL_WILDCARDS.includes(wildcard as OptionalWildcard));
  return OPTIONAL_WILDCARDS.includes(wildcard as OptionalWildcard);
}; 