import { TrainingMeta } from '../types/trainingMeta';
import { parseEthnicity } from './ethnicity';
// import { parseBodyType } from './bodyType';
import { getGenderDescriptor } from './gender';
import { getAgeDescription } from './age';
import { getGlassesDescription } from './glasses';
import { getHairDescription, getHairLengthDescription } from './hair';
import { removeExtraSpaces } from '../removeExtraSpaces';

const getFacialHairDescription = (gender: string, facialHair?: string): string => {
  if (gender !== 'Male' || !facialHair) return '';
  
  if (facialHair === 'None') {
    return 'and clean shaven';
  }
  
  return `and ${facialHair.toLowerCase()}`;
};

export const generateModelDescription = (trainingMeta: TrainingMeta, {
  // enabledAge = false,
  modifiers = {}
}: {
  enabledAge?: boolean;
  modifiers?: { [key: string]: string } | undefined,
}): string => {
  const gender = trainingMeta?.gender === 'Male' ? 'man' : 'woman';
  const genderDescriptor = getGenderDescriptor(trainingMeta?.gender || '');
  const age = getAgeDescription(trainingMeta?.age || '');

  /**
   * Generate the ethnicity description, if available. Otherwise, use an empty string.
   */
  const ethnicity = parseEthnicity(trainingMeta?.ethnicity || '', !trainingMeta?.ethnicity).trim();

  /**
   * Replace the {body type} placeholder with the actual body type, if available. Otherwise, replace it with an empty string.
   */
  // const bodyType = trainingMeta?.bodyType ? parseBodyType(trainingMeta.bodyType, trainingMeta.gender as 'Male' | 'Female').trim() : '';

  /**
   * Replace the {hair length} placeholder with the actual hair length, if available. Otherwise, replace it with an empty string.
   */
  const hair = getHairDescription(trainingMeta?.hairColor || '', trainingMeta?.hairLength || '', trainingMeta?.hairTexture || '', trainingMeta?.hairStyle || '');

  /**
   * Replace the {glasses} placeholder, if the model has the glasses attribute set to true. Otherwise, replace it with an empty string.
   */
  const glasses = getGlassesDescription(trainingMeta?.glasses || false, modifiers.glasses);

  const facialHair = getFacialHairDescription(trainingMeta?.gender || '', trainingMeta?.facialHair);

  const isNewVersion = trainingMeta.skinTone && trainingMeta.hairLength && trainingMeta.hairTexture && trainingMeta.hairStyle;
  if (!isNewVersion) {
    const hairLength = getHairLengthDescription(trainingMeta.hairLength);
    const skinTone = trainingMeta.skinTone ? `with ${trainingMeta.skinTone} skin tone` : '';
    return `${genderDescriptor} ${ethnicity} TOK ${gender} model ${hairLength} ${glasses} ${skinTone}`.trim();
  }

  return removeExtraSpaces(
    `${age} ${ethnicity} TOK ${gender} with ${trainingMeta?.eyeColor} eyes ${glasses} and ${hair} ${facialHair} and ${trainingMeta.skinTone} skin tone`.trim()
  );
  // 38 year old Caucasian TOK man with Blue eyes wearing glasses and Medium Medium Brown Straight Combed hair and clean shaven and Fair skin tone, looking at camera, eyes open
};

