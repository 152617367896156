export const PHOTO_STATUS = {
  // Initial Generation
  queued: "queued",  
  generating: "generating",
  
  // General Statuses (apply to any version)
  ready: "ready",          // The image version is ready for use.
  failed: "failed",        // The process for this version failed.
  
  // Process-Specific Statuses
  redoing_face: "redoing_face",    // Image is undergoing resemblance enhancement.
  face_detailer: "face_detailer",  // Image is undergoing face detailer.
  swapping: "swapping",      // Clothing swap is in progress. (we don't use this atm but leaving this here for the future)
  upscaling: "upscaling",    // Image is being upscaled.

   

  retrying: "retrying",
  upscaled: "upscaled"
} as const;

// You can also create a type from this object:
export type PhotoStatus = typeof PHOTO_STATUS[keyof typeof PHOTO_STATUS];