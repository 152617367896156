import { Button } from '@components/ui/button';
import { Card } from '@components/ui/card';
import { FaRegCirclePlay } from 'react-icons/fa6';
import { Dialog, DialogContent, DialogTrigger } from '@components/ui/dialog';
import React from 'react';
import { Avatar, AvatarFallback, AvatarImage } from '@components/ui/avatar';
import { CardContent } from '@components/ui/card';
import { GetYourPortraitsButton } from '@components/Button/get-your-portraits';

export default function KevinTimReaction() {
  return (
    <Card className="w-full p-0 relative overflow-hidden">
      <div className="grid grid-cols-1 lg:grid-cols-2">
        <div className="p-6 lg:p-12 flex flex-col justify-center">
          <div className="space-y-6 lg:max-w-xl">
            <div className="space-y-2">
              <h2 className="text-base font-semibold leading-7 text-accent">Top quality AI headshots</h2>
              <p className="w-full text-3xl md:text-6xl font-extrabold tight">"That's insanity!"</p>
            </div>
            <div className="space-y-6">
              <Card className="bg-foreground/5">
                <CardContent className="p-6">
                  <div className="flex space-x-5">
                    <Avatar className="h-14 w-14">
                      <AvatarImage src="https://imagedelivery.net/9sCnq8t6WEGNay0RAQNdvQ/d9621f06-72f7-43ce-b5f8-b809ab60d900/public" />
                      <AvatarFallback>TF</AvatarFallback>
                    </Avatar>
                    <div className="space-y-1">
                      <div className="flex items-center gap-2">
                        <h4 className="text-lg font-semibold leading-none">Tim Ferriss</h4>
                        <p className="text-base text-muted-foreground leading-none">@tferriss</p>
                      </div>
                      <p className="text-xl mt-4 font-medium">HOLY S***! That's insanity. 🤯</p>
                    </div>
                  </div>
                </CardContent>
              </Card>
              <Card className="bg-foreground/5">
                <CardContent className="p-6">
                  <div className="flex space-x-5">
                    <Avatar className="h-14 w-14">
                      <AvatarImage src="https://imagedelivery.net/9sCnq8t6WEGNay0RAQNdvQ/2f108238-4233-4710-9ec6-35a1c612b900/public" />
                      <AvatarFallback>KR</AvatarFallback>
                    </Avatar>
                    <div className="space-y-1">
                      <div className="flex items-center gap-2">
                        <h4 className="text-lg font-semibold leading-none">Kevin Rose</h4>
                        <p className="text-base text-muted-foreground leading-none">@kevinrose</p>
                      </div>
                      <p className="text-xl mt-4 font-medium">This brand new model is awesome! 🚀</p>
                    </div>
                  </div>
                </CardContent>
              </Card>
            </div>
            <GetYourPortraitsButton source="kevin_tim_reaction" className="w-fit" size="xl" />
          </div>
        </div>
        <div className="aspect-square relative h-full min-h-[400px] lg:min-h-[600px] bg-black  overflow-hidden">
          <Dialog>
            <DialogTrigger asChild>
              <Button size={'lg'} className="invert dark:invert-0 rounded-full absolute bottom-6 left-6 z-50">
                <FaRegCirclePlay className="mr-2" /> Watch Video
              </Button>
            </DialogTrigger>
            <DialogContent className="p-0 max-w-xl md:max-w-5xl overflow-hidden">
              <iframe
                src="https://www.youtube.com/embed/fm58FVusogo?autoplay=1&controls=0&modestbranding=1"
                frameBorder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen
                className="w-full aspect-video"
              ></iframe>
            </DialogContent>
          </Dialog>
          <video muted loop autoPlay className="h-full w-full object-cover absolute inset-0">
            <source src="/videos/kevin-tim-reaction-video.mp4" type="video/mp4" />
            <source src="/videos/kevin-tim-reaction-video.mp4" type="video/ogg" />
            Your browser does not support the video tag.
          </video>
        </div>
      </div>
    </Card>
  );
}
