type OutputMapping = {
  [outputKey: string]: string;
};

type WorkflowStep = {
  type: 'fal-workflow' | 'fal-comfyui-workflow';
  service: string;
  webhook_url: string;
  outputPath: string;
  outputMapping?: OutputMapping;
};

type WorkflowPipeline = {
  [step: number]: WorkflowStep;
};

export const INITIAL_GENERATION_SERVICE = 'workflows/Prompt-Hunt/initial-generation-production';
export const TRY_ON_SERVICE = 'workflows/Prompt-Hunt/try-on-production';
export const FACEDETAILER_SERVICE = 'comfy/Prompt-Hunt/facedetailer-production';
export const REDO_FACE_SERVICE = 'comfy/Prompt-Hunt/headshotenhancementressemblanceproduction';
const FAL_AI_CCSR_SERVICE = 'fal-ai/ccsr';

export const TRY_ON_WORKFLOW_PIPELINE: WorkflowPipeline = {
  1: {
    type: 'fal-workflow',
    service: INITIAL_GENERATION_SERVICE,
    webhook_url: 'https://www.picstudio.ai/webhooks/fal/pipeline',
    outputPath: 'payload.images[0]',
    outputMapping: {
      url: 'image_url'
    }
  },
  2: {
    type: 'fal-workflow',
    service: TRY_ON_SERVICE,
    webhook_url: 'https://www.picstudio.ai/webhooks/fal/pipeline',
    outputPath: 'payload.images[0]',
    outputMapping: {
      url: 'image_url'
    }
  },
  3: {
    type: 'fal-comfyui-workflow',
    service: FACEDETAILER_SERVICE,
    webhook_url: 'https://www.picstudio.ai/webhooks/fal/pipeline',
    outputPath: 'payload.outputs'
  }
};

export const HEADSHOT_WORKFLOW_PIPELINE: WorkflowPipeline = {
  1: {
    type: 'fal-workflow',
    service: INITIAL_GENERATION_SERVICE,
    webhook_url: 'https://www.picstudio.ai/webhooks/fal/pipeline',
    outputPath: 'payload.images[0]',
    outputMapping: {
      url: 'image_url'
    }
  },
  2: {
    type: 'fal-comfyui-workflow',
    service: FACEDETAILER_SERVICE,
    webhook_url: 'https://www.picstudio.ai/webhooks/fal/pipeline',
    outputPath: 'payload.outputs'
  }
};


export const HEADSHOT_WORKFLOW_PIPELINE_EXPERIMENTAL: WorkflowPipeline = {
  1: {
    type: 'fal-workflow',
    service: INITIAL_GENERATION_SERVICE,
    webhook_url: 'https://www.picstudio.ai/webhooks/fal/pipeline',
    outputPath: 'payload.images[0]',
    outputMapping: {
      url: 'image_url'
    }
  },
};

export const FACE_DETAILER_WORKFLOW_PIPELINE: WorkflowPipeline = {
  1: {
    type: 'fal-comfyui-workflow',
    service: FACEDETAILER_SERVICE,
    webhook_url: 'https://www.picstudio.ai/webhooks/fal/pipeline',
    outputPath: 'payload.outputs'
  }
};

export const REDO_FACE_WORKFLOW_PIPELINE: WorkflowPipeline = {
  1: {
    type: 'fal-comfyui-workflow',
    service: FACEDETAILER_SERVICE,
    webhook_url: 'https://www.picstudio.ai/webhooks/fal/pipeline',
    outputPath: 'payload.outputs'
  }
};

export const FAL_AI_CCSR_WORKFLOW_PIPELINE: WorkflowPipeline = {
  1: {
    type: 'fal-workflow',
    service: FAL_AI_CCSR_SERVICE,
    webhook_url: 'https://www.picstudio.ai/webhooks/fal/pipeline',
    outputPath: 'payload.image'
  }
};

export const PIPELINES = {
  TRY_ON_WORKFLOW_PIPELINE,
  HEADSHOT_WORKFLOW_PIPELINE,
  FACE_DETAILER_WORKFLOW_PIPELINE,
  HEADSHOT_WORKFLOW_PIPELINE_EXPERIMENTAL,
  REDO_FACE_WORKFLOW_PIPELINE,
  FAL_AI_CCSR_WORKFLOW_PIPELINE
};

export const getNextStep = (pipeline: keyof typeof PIPELINES, step: number): WorkflowStep | undefined => {
  const workflow = PIPELINES[pipeline];
  return workflow[step as keyof typeof workflow];
};
