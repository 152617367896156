import React from 'react';
import Image from 'next/image';
import { getOptimisedImageUrl } from '@lib/getOptimisedImage';
import { Button } from '@components/ui/button';
import { cn } from '@lib/utils';
import { cva } from 'class-variance-authority';
import { X } from 'lucide-react';
import { Card } from '@components/ui/card';
import { getPhotoSize } from '@acme/lib';
import { AspectRatio, PhotoSize } from '../../types/photo';

type ModelInfo = {
  name: string;
  avatar: string;
};

export type ShoppingCartItemProps = {
  title: string;
  mainImageSrc: string;
  models: ModelInfo[];
  quantity: number;
  price: number;
  clothing: string;
  isAdmin: boolean;
  onRemove?: () => void;
  onQuantityChange?: (quantity: number) => void;
  size?: 'default' | 'sm';
  photoSize?: {
    aspectRatio: AspectRatio;
    size: PhotoSize;
  };
  modelVersion?: '1.5' | 'flux.1-dev';
  photoQuantity?: number;
};

const shoppingCartItemVariants = cva('flex w-full gap-4', {
  variants: {
    size: {
      default: 'flex-row text-sm',
      sm: 'flex-col text-xs items-center'
    }
  },
  defaultVariants: {
    size: 'default'
  }
});

export function ShoppingCartItem({
  title,
  mainImageSrc,
  models,
  // quantity,
  // price,
  clothing,
  photoSize,
  isAdmin,
  onRemove,
  size = 'default',
  modelVersion,
  photoQuantity = 40
}: ShoppingCartItemProps) {
  return (
    <>
      <Card className="p-6 relative shadow-none border-[1px] border-border">
        <li className={cn(shoppingCartItemVariants({ size }))}>
          <div
            className={cn('relative overflow-hidden flex-shrink-0 rounded-lg aspect-[4/5]', {
              'max-w-32 h-full ': size === 'default',
              'max-w-32 h-full': size === 'sm'
            })}
          >
            <Image
              src={
                mainImageSrc
                  ? getOptimisedImageUrl(mainImageSrc, size === 'default' ? 200 : 150)
                  : '/images/pack-covers/blank.jpg'
              }
              className="object-cover aspect-[4/5]"
              alt={title}
              width={400}
              height={500}
            />
          </div>
          <div
            className={cn('w-full flex-grow justify-between text-sm flex gap-3', {
              'flex-row': size === 'default',
              'flex-col': size === 'sm'
            })}
          >
            <div className="flex-grow flex flex-col justify-between">
              <div className="space-y-1 text-muted-foreground mb-3">
                <h3 className="text-foreground text-xl">{title}</h3>
                <div className="text-muted-foreground">
                  {photoQuantity} photos{isAdmin && ` @ $${(photoQuantity * 0.04).toFixed(2)}`}
                </div>
                <div className="space-x-1 text-muted-foreground">
                  <span>Clothing: {clothing ? clothing : 'Random'}</span>
                  <span className="text-muted-foreground">•</span>
                  <span>Aspect ratio: {photoSize?.aspectRatio ? photoSize.aspectRatio : '1:1'}</span>
                  {isAdmin && (
                    <>
                      <span className="text-muted-foreground">•</span>
                      <span>
                        Size:{' '}
                        {photoSize?.size ? (
                          <>{getPhotoSize(photoSize.aspectRatio, photoSize.size, modelVersion).width} x </>
                        ) : (
                          ''
                        )}
                        {photoSize?.size
                          ? getPhotoSize(photoSize.aspectRatio, photoSize.size, modelVersion).height
                          : ''}
                      </span>
                    </>
                  )}
                </div>
              </div>
              <div className="flex flex-col gap-2">
                <div className="">Photos for</div>
                <div className="flex items-center gap-2">
                  {models.map((model, index) => (
                    <div key={index} className="flex items-center gap-2">
                      <Image src={model.avatar} alt={model.name} width={28} height={28} className="rounded-full" />
                      <span className="text-blue-800 dark:text-blue-300">{model.name}</span>
                      {index < models.length - 1 && <span className="text-muted-foreground">&</span>}
                    </div>
                  ))}
                </div>
              </div>
            </div>
            <div
              className={cn('flex flex-row sm:items-end mt-4 sm:mt-0 gap-4 sm:gap-2', {
                'flex-row': size === 'sm',
                'flex-col justify-end': size === 'default'
              })}
            >
              <div className="">
                <label htmlFor="quantity" className="sr-only">
                  Quantity
                </label>
              </div>
              {typeof onRemove === 'function' && (
                <Button
                  variant={'ghost'}
                  className="rounded-full text-muted-foreground absolute right-2 top-2"
                  onClick={onRemove}
                  size="icon"
                  aria-label="Remove item from cart"
                >
                  <span className="sr-only">Remove</span>
                  <X size={16} />
                </Button>
              )}
            </div>
          </div>
        </li>
      </Card>
    </>
  );
}
