import { get } from 'lodash';
import { ControlNetPoses, Model, Theme } from '@acme/db';
import { getPromptWithTriggers } from './getPromptWithTriggers';
import { AspectRatio, PhotoSize } from './types/photo';
import {prisma} from '@acme/db/client';
import { TrainingMeta } from './types/trainingMeta';
import { getPhotoSize } from './getPhotoSize';
import { parseShotType } from './parseShotTypeFromModifiers';
import { humanReadableShotType } from './humanReadableShotType';
import { DEFAULT_PHOTO_ALBUM_PHOTO_COUNT } from './global';
import "@acme/db/types"

const replaceShotType = (prompt: string, pose: ControlNetPoses): string => {
  const shotTypeRegex = /{shot[_]?[tT]ype(?::([\w-]+))?}/;
  return prompt.replace(shotTypeRegex, (_match, specificType) => {
    if (specificType) {
      return humanReadableShotType(specificType);
    }
    return humanReadableShotType(pose.shot_type || 'photo');
  });
};

// const getRandomElement = <T>(array: T[]): T => array[Math.floor(Math.random() * array.length)]!;

//only for chroma
// const DEFAULT_NEGATIVE_PROMPT =
//   'asian, multiple heads, 2 heads, elongated body, double image, 2 faces, multiple people, double head, topless, (nsfw)+, naked, nude, porn, open mouth, teeth, (sunglasses), black and white, monochrome, greyscale, grayscale, bw, CyberRealistic_Negative, CyberRealistic_Negative_v3, badhandv4, negative_hand-neg, BadDream, portrait, close up, sunglasses, sunglasses, bindi, pottu, deformed iris, deformed pupils, semi-realistic, cgi, 3d, render, sketch, cartoon, drawing, anime, illustration, cartoon, text, cropped, out of frame, worst quality, low quality, jpeg artifacts, duplicate, morbid, mutilated, extra fingers, mutated hands, poorly drawn hands, poorly drawn face, mutation, deformed, blurry, bad anatomy, bad proportions, extra limbs, cloned face, disfigured, gross proportions, malformed limbs, missing arms, missing legs, extra arms, extra legs, fused fingers, too many fingers, long neck, cellulite, pubic hair, armpit hair, body hair, nipples';

// const PROMPT_END =
//   'masterpiece, cinematic light, cinematic lighting, ultrarealistic, photorealistic, 8k, raw photo, realistic, sharp focus on eyes, symmetrical eyes, intact eyes, hyperrealistic, highest quality, best quality, highly detailed, masterpiece, best quality, extremely detailed 8k wallpaper, masterpiece, best quality, ultra-detailed, best shadow, detailed background, detailed face, detailed eyes, high contrast, best illumination, detailed face, dulux, caustic, dynamic angle, detailed glow, dramatic lighting, highly detailed, insanely detailed hair, symmetrical, intricate details, professionally retouched, 8k high definition, award winning photo,';

// const VINTAGE_PROMPT_END =
//   'vintage photograph, film grain, slightly soft focus, Kodachrome colors, warm tones, natural lighting, authentic style, period-correct details, medium format, gentle contrast, subtle vignette, classic composition, timeless portrait, genuine facial expression, well-posed subject, depth of field, mid-century modern aesthetic, nostalgic atmosphere, historically accurate, professionally photographed, gentle shadows, matte finish';

export const DEFAULT_USER_LORA_STRENGTH = 0.9;
export const DEFAULT_LORA_STRENGTH = 0.5;
export const DEFAULT_FLUXGUIDANCE_GUIDANCE = 3.5;
export const DEFAULT_KSAMPLER_STEPS = 25;
export const DEFAULT_KSAMPLER_CFG = 1;
export const DEFAULT_LORA_NAME = 'flux-RealismLora.safetensors';
// test



export const getInputsForBatchCreate = async (
  model: Model,
  theme: Theme,
  _isChroma: boolean,
  selectedAspectRatio: AspectRatio = '1:1',
  selectedSize: PhotoSize = 'small',
  modifiers?: { [key: string]: string } | undefined,
  photoQuantity?: number
) => {
  const referencePercentage = get(theme, 'meta.referencePercentage');
  const referenceImageUrl = get(theme, 'meta.referenceImageUrl');

  const strength = referenceImageUrl && referencePercentage ? 1 - Number(referencePercentage) / 100 : undefined;

  const shotType = parseShotType(theme.modifiers as any);
  console.log('SHOT TYPE', shotType);

  const poses = await prisma.controlNetPoses.findMany({
    where: {
      size: {
        in: ['5:4', '4:3', '3:2', '16:9'].includes(selectedAspectRatio)
          ? ['wide']
          : ['4:5', '3:4', '2:3', '9:16'].includes(selectedAspectRatio)
          ? ['tall', 'square']
          : ['square']
      },
      ...(shotType ? { shot_type: shotType } : {}),
      enabled: true
    }
  });

  const trainingMeta = model?.trainingMeta as unknown as TrainingMeta;
  let count =
    photoQuantity ||
    (process.env.NEXT_PUBLIC_DEFAULT_INPUTS_COUNT
      ? Number(process.env.NEXT_PUBLIC_DEFAULT_INPUTS_COUNT)
      : DEFAULT_PHOTO_ALBUM_PHOTO_COUNT);

  const { prompts } = await getPromptWithTriggers({
    theme,
    trainingMeta,
    count,
    modifiers
  });

  console.log('The length of prompts is', prompts.length);
  const { width, height } = getPhotoSize(selectedAspectRatio, selectedSize, model?.version as '1.5' | 'flux.1-dev');

  const baseInput = {
    negative_prompt: '',
    width,
    height,
    ksampler_steps: DEFAULT_KSAMPLER_STEPS,
    ksampler_cfg: DEFAULT_KSAMPLER_CFG,
    scheduler: 'euler',
    strength,
    fluxguidance_guidance: DEFAULT_FLUXGUIDANCE_GUIDANCE
  };


  return prompts.map((prompt, index) => {
    // const finalPrompt = `(headshot, analog style modelshoot style ${prompt}) ${PROMPT_END}`;
    const randomPose = poses[index % poses.length]!;
    const parsedPrompt = replaceShotType(prompt, randomPose);
    const finalPrompt = parsedPrompt

    // const fit = ['wide'].includes(getPhotoShape(selectedAspectRatio)) ? 'pad' : 'cover'; 
    return {
      name: `Image-${index}`,
      inputs: {
        ...baseInput,
        prompt: finalPrompt,
        lora_name: theme.meta?.lora_name || 'flux-RealismLora.safetensors',
        lora_strength: Number(theme.meta?.lora_strength || DEFAULT_LORA_STRENGTH),
        user_lora_strength: Number(theme.meta?.user_lora_strength || DEFAULT_USER_LORA_STRENGTH),
        fluxguidance_guidance: Number(theme.meta?.fluxguidance_guidance || DEFAULT_FLUXGUIDANCE_GUIDANCE),
        ksampler_steps: Number(theme.meta?.ksampler_steps || DEFAULT_KSAMPLER_STEPS),
        ksampler_cfg: Number(theme.meta?.ksampler_cfg || DEFAULT_KSAMPLER_CFG),
        ksampler_seed: Math.floor(Math.random() * 800000000 + 100000000),
        // face_detailer_prompt: `${model.triggerWord} with ${trainingMeta?.eyeColor} eyes ${
        //   trainingMeta?.glasses ? 'wearing glasses' : ''
        // }, skin pores, detailed`
        face_detailer_prompt: `${model.triggerWord} with ${trainingMeta?.eyeColor} eyes ${
          trainingMeta?.glasses ? 'wearing glasses' : ''
        }`
      }
    };
  });
};
