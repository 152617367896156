const AlbumType = {
  PrivateAlbum: 'PrivateAlbum',
  PublicAlbum: 'PublicAlbum',
  TeamAlbum: 'TeamAlbum'
} as const;

type Album = {
  meta: {
    isShared?: boolean;
  };
  teamId?: string | null;
};

type AlbumType = 'Private' | 'Public' | 'Team';

/**
 * Determines the album's access level based on its properties
 *
 * @param album - The album object to classify
 *
 * @remarks
 * ## Access Level Hierarchy:
 * 1. `Public`: Highest priority - when `isShared` is true
 * 2. `Team`: Middle priority - when `teamId` exists
 * 3. `Private`: Default - when neither condition applies
 *
 * @example
 * // Returns 'Public' (shared takes priority over team association)
 * getAlbumType({ meta: { isShared: true }, teamId: 'team_123' });
 */
export function getAlbumType(album: Album): AlbumType {
  if (album.meta.isShared) return 'Public';
  if (album.teamId) return 'Team';
  return 'Private';
}