import { logger } from "./logger";
import { fetchWildcards } from "./processPrompt";
import { ProcessedPrompts } from "./processPromptTriggers";
import { tag_category_type } from '@acme/db';
import { isOptionalWildcard } from './constants/wildcards';

export const generatePrompts = async (
  prompt: string,
  negativePrompt: string,
  count: number,
  categories: Awaited<ReturnType<typeof fetchWildcards>>,
  modifiers?: { [key: string]: string } | undefined
): Promise<ProcessedPrompts> => {

  const prompts: string[] = [];
  const negativePrompts: string[] = [];

  for (let i = 0; i < count; i++) {
    let promptCopy = prompt;
    logger.debug(`Generating prompt ${i + 1}`, { originalPrompt: promptCopy });

  
    // Process all modifiers ( values selected by the user )
    if (modifiers) {
      for (const [key, value] of Object.entries(modifiers)) {
        if (value === 'Random' && isOptionalWildcard(key)) {
          // Skip optional wildcards set to Random to default to None
          continue;
        }
        
        if (value !== 'Random') {
          logger.debug(`Replacing {${key}} with ${value}`);
          promptCopy = promptCopy.replaceAll(`{${key}}`, value);

          // If this modifier is not in the initialk promp, it may be a category, so we check that case and if that is strue, we replaced it
          if (!categories.tagModifiers.find(modifier => modifier.tag_name === key)) {
            const category = categories.categoryModifiers.find(modifier => modifier.tag_name === key);
            if (category) {
              logger.debug(`Found category ${category.category} for tag ${key}, replacing {${category.category}} with ${value}`);
              promptCopy = promptCopy.replaceAll(`{${category.category}}`, value);
            }
          }
        }
      }
    }

    const categoriesAvailable = categories.categoryModifiers.reduce((acc, modifier) => {
      if (modifier.category && !acc.includes(modifier.category)) {
        acc.push(modifier.category);
      }
      return acc;
    }, [] as tag_category_type[]);

    const tagsAvailable = categories.tagModifiers.reduce((acc, modifier) => {
      if (modifier.tag_name && !acc.includes(modifier.tag_name)) {
        acc.push(modifier.tag_name);
      }
      return acc;
    }, [] as string[]);
    logger.debug('Categories available', { categoriesAvailable })
    logger.debug('Tags available', { tagsAvailable })
    // First, we replace all the categories available. For example, if the prompt is {category1} {category2} {tag1} {tag2},
    // and the categories available are category1 and category2, we replace all {category1} and {category2} with a random value from the available categories.
    for (const category of categoriesAvailable) {
      const categoryModifiers = categories.categoryModifiers.filter(modifier => modifier.category === category);
      const randomCategoryModifier = categoryModifiers[Math.floor(Math.random() * categoryModifiers.length)];
      promptCopy = promptCopy.replaceAll(`{${category}}`, randomCategoryModifier?.value ?? '');
    }

    // Then, we replace all the tags available. For example, if the prompt is {tag1} {tag2} and the tags available are tag1 and tag2,
    // we replace all {tag1} and {tag2} with a random value from the available tags.
    for (const tag of tagsAvailable) {
      const tagModifiers = categories.tagModifiers.filter(modifier => modifier.tag_name === tag);
      const randomTagModifier = tagModifiers[Math.floor(Math.random() * tagModifiers.length)];
      promptCopy = promptCopy.replaceAll(`{${tag}}`, randomTagModifier?.value ?? '');
    }

    logger.debug(`Final prompt ${i + 1}`, { finalPrompt: promptCopy });
    prompts.push(promptCopy);
    negativePrompts.push(negativePrompt);
  }

  return { prompts, negativePrompts };
};
