import HowItWorks from '@components/Marketing/HowItWorks';
import Footer from '@components/Footer/Footer';
import React from 'react';
import Container from '@components/Container/Container';
import Packs from '@components/Marketing/Packs';
import Pricing from '@components/Model/Pricing/Pricing';
import Examples from '@components/Marketing/Cards/Examples';
import MinimalNav from '@components/Nav/MinimalNav';
import { NextSeo } from 'next-seo';
import { trackEvent } from '@analytics/trackEvent';
import { AnalyticsEvents } from '@analytics/AnalyticsEvents';
import PhotoGallery from '@components/Marketing/PhotoGallery';
import FAQ from '@components/FAQ/FAQ';
import Usecases from '@components/Marketing/Usecases';
import Quality from '@components/Marketing/Quality';
// import Hero111424 from '@components/Marketing/Hero-11-14-24';
import OneTapComponent from '@components/OneTap/OneTapComponent';
import ProHeadshotsNoPhotographer from '@components/Marketing/Heroes/pro-headshots-no-photographer';
import AffiliateProgramCard from '@components/Cards/AffiliateProgramCard';
import BrowsePhotoPacksCard from '@components/Cards/BrowsePhotoPacksCard';
import ComparisonTable from '@components/Marketing/comparison-table';
import KevinTimReaction from '@components/Marketing/KevinTimReaction';
// import CarouselHero from '@components/Marketing/Heroes/carousel-hero';

export default function Marketing() {
  React.useEffect(() => {
    trackEvent(AnalyticsEvents.marketingView, {
      page: 'Marketing'
    });
  }, []);

  return (
    <>
      <NextSeo
        title="PicStudio.AI: Pro portraits created with AI"
        description="The easiest way to professional portraits with AI! Upload selfies, choose a photo pack & receive 120+
        stunning portraits. Perfect for social media."
        openGraph={{
          images: [
            {
              url: 'https://www.picstudio.ai/default-cover-sep-2024.jpg',
              secureUrl: 'https://www.picstudio.ai/default-cover-sep-2024.jpg',
              width: 1200,
              height: 600,
              alt: 'PicStudio.AI Cover',
              type: 'image/jpg'
            }
          ]
        }}
        twitter={{
          handle: '@picstudioai',
          site: '@picstudioai',
          cardType: 'summary_large_image'
        }}
      />
      <MinimalNav className="bg-transparent" />
      {/* <Hero111424 /> */}
      {/* <CarouselHero /> */}
      <ProHeadshotsNoPhotographer />
      <Container className="max-w-screen-2xl">
        <div className="bg-background">
          <div className="flex flex-col gap-8">
            <HowItWorks />
            <KevinTimReaction />

            <Packs className="mt-6" />

            <div className="">
              <PhotoGallery />
              {/* <VideoSection /> */}
            </div>
            {/* <Hero2 /> */}
            <div className="lg:mt-16">
              <Usecases />
              <Quality />
            </div>
            <div className="grid grid-cols-1 md:grid-cols-3 gap-8 items-start">
              {/* <OpenPacks /> */}
              <BrowsePhotoPacksCard />
              <Examples />
              <AffiliateProgramCard />
            </div>

            <ComparisonTable />

            <div id="pricing">
              <Pricing />
            </div>
            <FAQ />
          </div>
        </div>
      </Container>
      <Footer />
      <OneTapComponent />
    </>
  );
}
